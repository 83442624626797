// piechart.jsx
import React from 'react';
import { Pie } from 'react-chartjs-2';
import 'chart.js/auto';

export const data = {
  labels: ['Liquidity', 'Marketing', 'Team Expenses'],
  datasets: [{
    label: 'Token Distribution',
    data: [90, 5, 5],
    backgroundColor: ['#003d66', '#3f86b6', '#6292b9'],
    hoverOffset: 4
  }]
};

export const options = {
    maintainAspectRatio: false,
    responsive: true,
    aspectRatio: 1,
    plugins: {
      legend: {
        position: 'top',
        fullSize: false, // This allows the legend to only take up as much space as needed for its items
        align: 'center', // Aligns the legend to the start (left)
        labels: {
          color: 'white',
          padding: 15,
          boxWidth: 40,
        }
      },
      tooltip: {
        enabled: true
      }
    },
  layout: {
    padding: {
      top: 10,
      bottom: 0,
      left: 0,
      right: 0
    }
  }
};

const PieChart = () => {
  return <Pie data={data} options={options} />;
};

export default PieChart;
