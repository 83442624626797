import dex from "../img/249x80_cyan light.png";
import dexscreener from "../img/dexscreener.png";
// import email from "../img/email.png";
import { motion } from 'framer-motion';

import { useAnimation } from 'framer-motion';



import { Pie } from 'react-chartjs-2'; // This import seems unnecessary here if you are using PieChart from Piechart.jsx
import 'chart.js/auto';
import { data, options } from './Piechart'; // Ensure this path correctly points to where Piechart.jsx is located relative to Main.jsx


// import WalletConnect from "./Metamask";

import React, { useRef, useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { Link } from "react-scroll";
import Airdrop from "./Airdrop";
import logo from "../img/logo.jpg";
import gif from "../img/runningGif.gif";
import PieChart from './Piechart';

export const Main = () => {
    const [isVisible, setIsVisible] = React.useState(false);
    const [isChatVisible, setIsChatVisible] = React.useState(false);
    const [isImageVisible, setIsImageVisible] = React.useState(false);
    const lastContainerRef = React.useRef(null);
    const imgContainerRef = React.useRef(null);

    const [firstListVisible, setFirstListVisible] = useState(false);
    const [secondListVisible, setSecondListVisible] = useState(false);
    const [thirdListVisible, setThirdListVisible] = useState(false);
    const [fourthListVisible, setFourthListVisible] = useState(false);

    const firstListRef = useRef(null);
    const secondListRef = useRef(null);
    const thirdListRef = useRef(null);
    const fourtgListRef = useRef(null);

    const { ref: firstRef, inView: firstInView } = useInView();
    const { ref: secondRef, inView: secondInView } = useInView();
    const { ref: thirdRef, inView: thirdInView } = useInView();
    const { ref: fourthRef, inView: fourthInView } = useInView();

    useEffect(() => {
        setFirstListVisible(firstInView);
        setSecondListVisible(secondInView);
        setThirdListVisible(thirdInView);
        setFourthListVisible(fourthInView);
    }, [firstInView, secondInView, thirdInView, fourthInView]);
    

    const controls = useAnimation();
    // const [ref, inView] = useInView();

    // const [ref, inView] = useInView({
    //     triggerOnce: false,
    //     threshold: 0.01  // Lower threshold to trigger visibility earlier
    // });
    
    const [ref, inView] = useInView({
        triggerOnce: false,
        threshold: 0.1,
        rootMargin: "300px 0px 300px 0px"  // Adjust top and bottom margins to trigger earlier
    });
    
   
    React.useEffect(() => {
        const imgObserver = new IntersectionObserver(entries => {
            const entry = entries[0];
            if (entry.isIntersecting) {
                setIsImageVisible(true);  // Show the image when in view
            } else {
                setIsImageVisible(false); // Optional: decide based on need
            }
        }, { threshold: 0.8 });
    
        if (imgContainerRef.current) {
            imgObserver.observe(imgContainerRef.current);
        }
    
        return () => {
            if (imgContainerRef.current) {
                imgObserver.unobserve(imgContainerRef.current);
            }
        };
    }, []);
 

    React.useEffect(() => {
        if (inView) {
            controls.start('visible');
        }
    }, [controls, inView]);

    const navigate = (url) => {
        window.location.href = url;
    }

    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
          if (entries[0].isIntersecting) {
            setIsVisible(true);
            setTimeout(() => setIsChatVisible(true), 500); // Delay visibility
          }
        }, { threshold: 0.5 });
      
        if (lastContainerRef.current) {
          observer.observe(lastContainerRef.current);
        }
      
        return () => {
          if (lastContainerRef.current) {
            observer.unobserve(lastContainerRef.current);
          }
        };
      }, []);
      

// Define animation variants
const variants = {
  hidden: { opacity: 0, x: -200 }, // Start from the right
  visible: { opacity: 1, x: 0, transition: { type: 'spring', duration: 1 } }
};
const leftVariants = {
    offscreen: { x: -100, opacity: 0 },
    onscreen: {
      x: 0,
      opacity: 1,
      transition: {
        staggerChildren: 0.2,  // 200ms between each child animation start
        duration: 0.5
      }
    }
  };
  
  const rightVariants = {
    offscreen: { x: 100, opacity: 0 },
    onscreen: {
      x: 0,
      opacity: 1,
      transition: {
        staggerChildren: 0.2,  // 200ms between each child animation start
        duration: 0.5
      }
    }
  };
  
  React.useEffect(() => {
    const observer = new IntersectionObserver(entries => {
        const [entry] = entries;
        // Set visibility based on whether the .last-container is intersecting
        if (entry.isIntersecting) {
            setIsVisible(true);
            setTimeout(() => setIsChatVisible(true), 500); // Adjust this delay as necessary
        } else {
            setIsVisible(false);
            setIsChatVisible(false); // Optionally reset visibility when it's not visible
        }
    }, {
        threshold: 0.1 // Trigger when 10% of the element is in view (adjust as needed)
    });

    if (lastContainerRef.current) {
        observer.observe(lastContainerRef.current);
    }

    return () => {
        if (lastContainerRef.current) {
            observer.unobserve(lastContainerRef.current);
        }
    };
}, []);


const howToBuyVariants = {
    hidden: {
        scale: 0.1,
        opacity: 0,
        transition: {
            type: 'spring',
            duration: 3
        }
    },
    visible: {
        scale: 1,
        opacity: 1,
        transition: {
            type: 'spring',
            stiffness: 260,
            damping: 20
        }
    }
};


    const handleClaimClick = () => {
       
        console.log("Claim button clicked");
    }

    const li = [
        { id: 1, title: "Sanik" },
        { id: 2, title: "Buy" },
        { id: 3, title: "Lore" },
        { id: 4, title: "Roadmap" },
        { id: 5, title: "JoinUs" },
        
    ];

    return (
        <>
            <div className="main-land">
                <header className="sticky">
                    <ul className='nav'>
                        {li.map(menu => (
                            <li className="catpaw" key={menu.id}>
                                <Link to={menu.title} smooth={true} duration={1000} className="link">{menu.title}</Link>
                            </li>
                        ))}
                       
                    </ul>
                    {/* <button className="buy-btn" onClick={() => navigate('https://beta.raydium.io/swap/?inputMint=sol&outputMint=3Rih33snXVQq8vBuoZ3Fm62Rqm4GZY1LKhedTkmD4BHF')}>BUY</button> */}
                    <ul className='socials-nav'>
                        
                    <li><a href="https://discord.gg/wPpstxek" className='socials-li'>discord</a></li>
                        <li><a href="https://t.me/+SGTCXq8KPHRhODJk" className='socials-li'>telegram</a></li>
                        
                        <li><a href="https://x.com/Sanikgofast2000" className='socials-li'>X</a></li>
                    </ul>
                    
                    
                    <img src={logo} className="io-logo" alt="" />
                </header>
                <main>
                    <div className="content main-land">
                        <div className="welcome-bg"></div>
                        <h1 className="content-header" id="Sanik">Sanik</h1>
                        <p className="have-you-ever-txt typewriter" id="contract-adr">have u ever went fast</p>
                        <div className="io-front">
                            <h2 className="io-header" id="io-font-txt">Fantom Sanik</h2>
                        </div>
                        
                        {/* <div className="welcome-img fade-in-left"></div> */}
                        <div className="dexes">
                    <a href="https://www.dextools.io/app/en/fantom/pair-explorer/0x6ff533ff6842348ae5a7bf11c7f80ab737d20e7f" rel="noopener noreferrer"><img src={dex} alt="" className="dex"/></a>
                    <a href="https://dexscreener.com/fantom/0x6ff533ff6842348ae5a7bf11c7f80ab737d20e7f" rel="noopener noreferrer"><img src={dexscreener} alt="" className="dexscreener"/></a>
                    </div>
                    </div>
                    {/* <div className="section-splitter"></div> */}
                    <div className="content social">
                        <h1 className="content-header" id="Buy">Buy</h1>
                        <h1 className="social-header">How to buy</h1>
                      <div className="howtobuy-container">
                      <motion.div 
                            className="howtobuy-step"
                            variants={howToBuyVariants}
                            initial="hidden"
                            animate={inView ? "visible" : "hidden"}
                            ref={ref}
                                >
    

                            <h3 className="howtobuy-header">Create a wallet</h3>
                            <p className="howtobuy-txt">You can download MetaMask or any wallet you prefer from the App Store or Google Play Store at no cost. For desktop users, you can obtain the Google Chrome extension by visiting metamask.io</p>

                        </motion.div>
                        <motion.div 
                            className="howtobuy-step"
                            variants={howToBuyVariants}
                            initial="hidden"
                            animate={inView ? "visible" : "hidden"}
                            ref={ref}
                                >
                            <h3 className="howtobuy-header">Get some FTM</h3>
                            <p className="howtobuy-txt">Make sure you have Fantom (FTM) in your wallet to swap to Fantom Sanik (S). If you don't have FTM, you can buy it on MetaMask, transfer it from another wallet, or purchase it on another exchange and transfer it to your wallet.
Choose one of our listed exchanges and connect to the DEX with your wallet. Select Fantom Sanik (S) and confirm.</p>
                        </motion.div>
                        <motion.div 
                            className="howtobuy-step"
                            variants={howToBuyVariants}
                            initial="hidden"
                            animate={inView ? "visible" : "hidden"}
                            ref={ref}
                                >
                            <h3 className="howtobuy-header">Go to one dex listed below</h3>
                            <p className="howtobuy-txt">Connect to dex with your wallet. Select Fantom Sanik S, and confirm. When Metamask prompts you for a wallet signature, sign.</p>
                            <ul className="howtobuy-list">
                                <li><a href="https://degen.express/fantom/token/0xac641e94b9E7831eC7929181188938E76A98030F">Degen express</a></li>
                                <li><a href="https://beets.fi/pool/0x85d8652d4ff1921bbf3b64f42ba5ea425e6df52f000200000000000000000847" rel="noopener noreferrer">Beethoven X</a></li>
                                <li><a href="https://equalizer.exchange/swap">Equalizer Exchange</a></li>
                            </ul>
                        </motion.div>
                        {/* <motion.div 
                            className="howtobuy-step"
                            variants={howToBuyVariants}
                            initial="hidden"
                            animate={inView ? "visible" : "hidden"}
                            ref={ref}
                                >
                            <h3 className="howtobuy-header">Done!</h3>
                            
                        </motion.div> */}
                      </div>
                      
                      <h1 className="bridge-header">How to bridge</h1>
                      <p className="howtobridge-iftxt">
                                If you don't buy directly from Metamask and need to bridge, you can use <a href="https://portalbridge.com/">portalbridge.com</a> or <a href="https://app.rocketx.exchange/?ref=lws0uzUt">rocketx.exchange</a> to bridge to Fantom
                                </p>
                      <div className="howtobuy-container">
                        
                     
                      <motion.div 
                            className="howtobuy-step bridge"
                            variants={howToBuyVariants}
                            initial="hidden"
                            animate={inView ? "visible" : "hidden"}
                            ref={ref}
                                >
                            <h2 className="howtobridge-txt">Connect your wallet and choose "Network"</h2>
                            <p className="howtobuy-txt"></p>
                        </motion.div>
                        <motion.div 
                            className="howtobuy-step bridge"
                            variants={howToBuyVariants}
                            initial="hidden"
                            animate={inView ? "visible" : "hidden"}
                            ref={ref}
                                >
                            <h2 className="howtobridge-txt">Choose the "Asset"</h2>
                        </motion.div>
                        <motion.div 
                            className="howtobuy-step bridge"
                            variants={howToBuyVariants}
                            initial="hidden"
                            animate={inView ? "visible" : "hidden"}
                            ref={ref}
                                >
                            <h2 className="howtobridge-txt">Enter the "Amount"</h2>
                            
                        </motion.div>
                        <motion.div 
                            className="howtobuy-step bridge"
                            variants={howToBuyVariants}
                            initial="hidden"
                            animate={inView ? "visible" : "hidden"}
                            ref={ref}
                                >
                            <h2 className="howtobridge-txt">Then, confirm the transaction</h2>
                        </motion.div>
                        
                        
                      </div>
                      <h1 className="social-header">Partners</h1>
                        <div className='collabs-container'>
                   <motion.div className='collabs-sort' variants={leftVariants} initial="offscreen" whileInView="onscreen" viewport={{ once: false, amount: 0.5 }}>
    {/* Add collabs from the left side */}
    <a href="https://degen.express/?ref=sanik" className='collab'>
        <div className="collabOne"></div>
        <p className="collab-txt">Degenexpress</p>
    </a>
    <a href="https://coinmarketcap.com/" className='collab'>
        <div className="collabTwo"></div>
        <p className="collab-txt">Coinmarketcap</p>
    </a>
</motion.div>
<motion.div className='collabs-sort' variants={leftVariants} initial="offscreen" whileInView="onscreen" viewport={{ once: false, amount: 0.5 }}>
                            <a href="https://www.dextools.io/app/en/fantom/pair-explorer/0x6ff533ff6842348ae5a7bf11c7f80ab737d20e7f" rel="noopener noreferrer" className='collab'>
                                <div className="collabFive"></div>
                                <p className="collab-txt">Dextools</p>
                            </a>
                            <a href="https://beets.fi/pool/0x85d8652d4ff1921bbf3b64f42ba5ea425e6df52f000200000000000000000847" className='collab'>
                                <div className="collabSix"></div>
                                <p className="collab-txt">Beets</p>
                            </a>
                            </motion.div>

<motion.div className='collabs-sort' variants={rightVariants} initial="offscreen" whileInView="onscreen" viewport={{ once: false, amount: 0.5 }}>
    {/* Add collabs from the right side */}
    <a href="https://www.geckoterminal.com/ftm/pools/0x6ff533ff6842348ae5a7bf11c7f80ab737d20e7f" className='collab'>
        <div className="collabThree"></div>
        <p className="collab-txt">Coingecko</p>
    </a>
    <a href="https://dexscreener.com/fantom/0x6ff533ff6842348ae5a7bf11c7f80ab737d20e7f" className='collab'>
        <div className="collabFour"></div>
        <p className="collab-txt">Dexscreener</p>
    </a>
</motion.div>
<motion.div className='collabs-sort' variants={rightVariants} initial="offscreen" whileInView="onscreen" viewport={{ once: false, amount: 0.5 }}>
                            <a href="https://equalizer.exchange/swap" className='collab'>
                                <div className="collabSeven"></div>
                                <p className="collab-txt"> Equalizer</p>
                               
                            </a>
                            <a href="" className='collab'>
                                {/* <div className="collabEight"></div> */}
                                <p className="collab-txt"> Coming soon..</p>
                            </a>
                            
                            </motion.div>

</div>

                        <div className="urnotfast"></div>
                        <div className="uwu"></div>
                    </div>
                    <div className="content howitworks">
                        <div className='fade-bg'></div>
                        <h1 className="content-header" id="Lore">Lore</h1>
                        {/* <div className="gif-fade"></div> */}
                        <p className="howitworks-header">
                        
                        We da fast spedy meme on Fantom Sanik
                        
                        </p>
                        
                        <div className="howitworks-content">
                            <div className="howitworks-container proposal-container">
                           
                            <p className='hover-txt'>Hover to reveal</p>
                            <div className="proposal">So, one day in teh crypto wildz, da difu legund Andre Cronje drops two memes <a href="https://x.com/AndreCronjeTech/status/1759974506028847498">1</a> and <a href="https://x.com/AndreCronjeTech/status/1777753111433544038">2</a>. Dis meme wuz so memeatic and da lack of knowledge of Fantom's speed spawned our hero, 
                            <a href="">Sanik</a>. ZOOM ZOOM! Sanik bursts into existence, super sonic fast, sped liek a rocket, da spedy saviour, dank as evah and will win.

Sanik zooms through Fantom, spreading lulz and fastness, leaving fas sped and low finality in his wake. We do Fantom native memes, cuz Fantom is our playground. Now, he's da ultimate fast meme lord of Fantom, ready to take on the meme-world in fast sped and fastness.
                            </div>
                            <div className='meme-div'>
                                <div className='meme1'></div>
                                <div className='meme2'></div>
                            </div>
                            
                        </div>
                        <div className="howitworks-wrapper">
                        
                        
                        </div>
                        <div className="how-sort">
                        <div className="howitworks-wrapper">
                        <div className="howitworks-container">
                            <p className="howitworks-txt">No honeypot, fake volume, or CEX to lose your money. <br/>No colluding OTC for fat team gains on your behalf. <br/>100% on-chain and verifiable, as crypto should be</p>

                        </div>
                        <div className="howitworks-container">
                            <p className="howitworks-txt"> 5% marketing and 5% team expenses will be vested over 180 days linear</p>

                        </div>
                        <div className="howitworks-container">
                            <p className="howitworks-txt">We have renounced ownership, mint and freeze for your security</p>

                        </div>
                        </div>
                        <div className="howitworks-wrapper">
                        <div className="howitworks-container">
                            <p className="howitworks-txt">No rugs allowed lp locked, marketing and team expenses vested 180 days linear for a non trust situation</p>

                        </div>
                        <div className="howitworks-container">
                            <p className="howitworks-txt">
                                We will launch on Degenexpress as advised by the Fantom Foundation
                            </p>

                        </div>
                        <div className="howitworks-container">
                            <p className="howitworks-txt">Our goal is simple, to be the fastest growing meme on Fantom Sanik, with the community steering the spinball </p>

                        </div>
                        </div>
                        </div>

                        <div className='token-dist-container'>
                            <div className="chart-container">
                                <PieChart />
                            </div>
                            <motion.div className="chart-img" variants={rightVariants} initial="offscreen" whileInView="onscreen" viewport={{ once: false, amount: 0.5 }}></motion.div> 
                        </div>

                        
                        </div>
                        
                       
                    </div>
                    <div className="content tokenomics">
                    <h1 className="content-header" id="Roadmap">Roadmap</h1>
                        <div className="img-container" ref={imgContainerRef} >
                            <div className={`tokenomics-img-bg ${isImageVisible ? 'animation-start' : ''}`}></div>
                            <div className='roadmap-chat-img'><p className='roadmap-txt'>RÖAD MÄP!</p></div>
                        </div>
                       
                       
                        <div className="tokenomics-total">

                        <div className="tokenomics-content " ref={firstRef}>
                            <h3 className="tokenomics-header">Phase 1: Socil Growf n contestz</h3>
                            
                                <ul className='tokenomics-txt'>
                                {["Soshal Media Hype: Run cray-cray contestz n make viral meme 4 boost engagemnt n brand visibiltie.", "Comminty Vibez: Turn soshal media into buzzin Sanik parteh!"]
        .map((item, index) => (
            <motion.li 
                className=" t-txt-one" 
                variants={variants} 
                initial="hidden" 
                animate={firstListVisible ? 'visible' : 'hidden'}
                key={index}>
                {item}
            </motion.li>
        ))}
        
                                    {/* <li className="tokenomics-txt t-txt-one">Launching the Meme Maker: We’re rolling out a super fast meme maker that lets everyone create, share, and even earn from their memes. It’s all about making meme magic easy and fun.</li>
                                    <li className="tokenomics-txt t-txt-one">Building our fastest Sanik Tribe: We're hitting social media hard, teaming up with top meme lords and defi gods to get the word out and pull in a crowd that loves to meme and be fast.</li> */}
                                </ul>
                                
                                
                            <div className="tokenomics-img-one"></div>
                        </div>
                        <div className="tokenomics-content" ref={secondRef}>
                            
                            <h3 className="tokenomics-header">Phase 2: Epiq Partnaships n Legendery NFTs</h3>
                            
                                <ul className='tokenomics-txt'>
                                {[ 
        "Event Madness: Collab on epiq eventz n promos.", "NFT Magic: Drop NFTz wif unique lottery powerz!"]
        .map((item, index) => (
            <motion.li 
                className="t-txt-two" 
                variants={variants} 
                initial="hidden" 
                animate={secondListVisible ? 'visible' : 'hidden'}
                key={index}>
                {item}
            </motion.li>
        ))}

                                </ul>
                            <div className="tokenomics-img-two"></div>
                        </div>
                        <div className="tokenomics-content content-three" ref={thirdRef}>
                           
                            <h3 className="tokenomics-header">Phase 3: Branding n Merch Exploshun</h3>
                            
                                <ul className='tokenomics-txt'>
                                    
                                    {["Sanik Swag: Launch awesum merch like t-shirtz, hatz, n stickrz 2 spread da Sanik luv.", "Influencer Hype: Get influenzers 2 rock our merch n hype it up!"]
        .map((item, index) => (
            <motion.li 
                className="t-txt-three" 
                variants={variants} 
                initial="hidden" 
                animate={thirdListVisible ? 'visible' : 'hidden'}
                key={index}>
                {item}
            </motion.li>
        ))}
                                </ul>
                                <div className="tokenomics-img-three"></div>
                        </div>
                        <div className="tokenomics-content content-four" ref={fourthRef}>
                            
                             <h3 className="tokenomics-header">Phase 4: Marketing Frenzy & Future
</h3>
                            
                                <ul className='tokenomics-txt'>
                                  
                                    {["Marketing Blitz: Keep da hype train rollin wif nonstop marketing.", "Community-Powered Future: Da future iz choosen by da community, unleashin da powr of collective vision!"]
        .map((item, index) => (
            <motion.li 
                className="t-txt-four" 
                variants={variants} 
                initial="hidden" 
                animate={fourthListVisible ? 'visible' : 'hidden'}
                key={index}>
                {item}
            </motion.li>
        ))}
                                </ul>
                            <div className="tokenomics-img-four"></div>
                        </div>
                        </div>
                    </div>
                    <div className={`content last-container ${isVisible ? 'visible' : ''}`} ref={lastContainerRef}>
                        <h1 className="content-header" id="JoinUs">Join Us</h1>
                        <Airdrop />
                        {/* <h1 className="claim-txt-mobile">Claimdrop coming soon</h1> */}
                        <div className={`airdrop-container ${isChatVisible ? 'visible' : ''}`}>
                    <h1 className="claim-txt">
                            Join us on <a href="https://t.me/+SGTCXq8KPHRhODJk" className='socials'>telegram</a>, <a href="https://discord.gg/wPpstxek" className='socials'>discord</a> & <a href="https://x.com/Sanikgofast2000" className='socials'>X</a>
                        </h1>
                    {/* <button className="claim-btn" onClick={handleClaimClick}>
                    
                        <p className="btn-letter l1">C</p>
                        <p className="btn-letter l2">L</p>
                        <p className="btn-letter l3">A</p>
                        <p className="btn-letter l4">I</p>
                        <p className="btn-letter l5">M</p>
                    </button> */}
                    {/* <div className="hidden-info">
                            <p className="hidden-txt">Click it</p>
                        </div> */}
                        {/* <img src={arrow} alt="" /> */}
                        
                        

                    </div>
                        <div className="gif-container">
                        <p className='rights-txt'>© 2024 by SANIK. All rights reserved!</p>
                         <img src={gif} alt="Moving GIF" className="moving-gif" />
                         </div>
                     
                    </div>
                  
                    
                    
                    
                </main>
            </div>
        </>
    );
};