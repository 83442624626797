import './App.css';
import { Main } from './components/Main.jsx';

function App() {
  return (
    <div className="App">
      <Main/>
    </div>
  );
}

export default App;
