import React, { useEffect,useState } from 'react';
import { ethers } from "ethers";
import abi from "../ABI/Io.json"

function Airdrop() {

  const [account, setAccount] = useState("");

  // useEffect(() => {

   
  //   getAcc();
    

  // },[account])

//   const getAcc = async() => {
//     await window.ethereum.request({method: 'eth_requestAccounts'}).then(result =>{
//       setAccount(result[0])
//     })
// }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { ethereum } = window;
    if (ethereum) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const contractAddress = process.env.REACT_APP_IO_CONTRACT;
        const contract = new ethers.Contract(contractAddress, abi, signer);
        const claimed = await contract.claimTokens();
        const reciept = await claimed.wait();
        if(reciept.status===1){
            console.log("Success");
            console.log(reciept);

        }
    }
  };

  return (
    <div className="airdrop-container">
      <h1 className="claim-txt">Claim your blabla here</h1>
      
      <button className="claim-btn" onClick={handleSubmit}>Claim</button>
  
    </div>
  );
}

export default Airdrop;
